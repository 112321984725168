export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/about": [28],
		"/fb/users": [29],
		"/fb/users/[uid]": [30],
		"/me": [31],
		"/wim": [32,[3]],
		"/wim/app-instances": [33,[3]],
		"/wim/app-urls": [34,[3]],
		"/wim/app-urls/[id]": [35,[3]],
		"/wim/clients": [36,[3]],
		"/wim/clients/[id]": [37,[3]],
		"/wim/member-apps": [38,[3]],
		"/wim/member-apps/build-process": [40,[3]],
		"/wim/member-apps/[id]": [39,[3]],
		"/wim/static-apps": [41,[3]],
		"/wim/static-apps/[id]": [42,[3]],
		"/[stage]": [5,[2]],
		"/[stage]/config/audit-logs": [6,[2]],
		"/[stage]/config/auth-logs": [7,[2]],
		"/[stage]/config/auth-logs/[id]": [8,[2]],
		"/[stage]/config/auth-mappings": [9,[2]],
		"/[stage]/config/auth-providers": [10,[2]],
		"/[stage]/config/oauth2-defs": [11,[2]],
		"/[stage]/config/saml-defs": [12,[2]],
		"/[stage]/config/saml-defs/[id]": [13,[2]],
		"/[stage]/mng/docs": [14,[2]],
		"/[stage]/mng/mobis": [15,[2]],
		"/[stage]/mng/portals": [16,[2]],
		"/[stage]/mng/portals/add": [17,[2]],
		"/[stage]/mng/user-activities": [18,[2]],
		"/[stage]/mng/users": [19,[2]],
		"/[stage]/mng/users/add": [23,[2]],
		"/[stage]/mng/users/[userId]/activities": [20,[2]],
		"/[stage]/mng/users/[userId]/edit": [21,[2]],
		"/[stage]/mng/users/[userId]/info": [22,[2]],
		"/[stage]/my": [24,[2]],
		"/[stage]/my/domains": [25,[2]],
		"/[stage]/my/mobis": [26,[2]],
		"/[stage]/my/portals": [27,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';